import React from 'react';
import { Box, Typography } from '@mui/material';

export default function CautionBar() {
  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: '#FFA726',
        color: '#000',
        textAlign: 'center',
        padding: '8px',
        top: 0,
        left: 0
      }}>
      <Typography variant="body1">
        CAUTION: You are in the Dev environment. Changes made here do not affect production.
      </Typography>
    </Box>
  );
}
